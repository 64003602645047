import React, { useState, useEffect } from "react";
import { storeToken, storeDisplayName } from '../../Auth'
import Api from "../../services/api";
import {
  Redirect, Link
} from 'react-router-dom'
import LeftPanel from "../../pages/login-and-registration/LeftPanel";
import { careLynkLogo, exclamatoryRedDark, eye, eyeoff } from "../../assets/icons";
const LoginForm = ({ loginType }) => {
  const [errors, updateErrors] = useState({});
  const [data, updateData] = useState({
    loginLink: "",
    forgotPasswordLink: "",
    loginViewText: "",
    requestUrl: ""
  })

  const [notAuthorized, updateNotAuthorized] = useState(false);
  const [loggedIn, updateLoggedIn] = useState(false);
  const [show_password, updateShowPassword] = useState(false);
  const [isLoading, updateIsLoading] = useState(false);
  const [disabled, updateDisabled] = useState(true);
  const errorMessage = "Invalid username or Password, please try again";
  const [credentials, updateCredentials] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {
    if (loginType === 'Branch Login') {
      data.loginLink = "/admin/login";
      data.forgotPasswordLink = "/provider/forgotPassword";
      data.loginViewText = "For pan India view";
      data.requestUrl = "/login";
    } else {
      data.loginLink = "/login";
      data.forgotPasswordLink = "/enterprise/forgotPassword";
      data.loginViewText = "For branch view";
      data.requestUrl = "/enterprise/login";
    }
    updateData({ ...data })
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateNotAuthorized(false)
    var IsFormValid = handleValidation();
    if (IsFormValid) {
      handleLogin();
    }
  }

  const toggleVisiblity = (event) => {
    const element = document.getElementById('input_password');
    const type = element.type === 'password' ? 'text' : 'password';
    element.setAttribute('type', type);
    updateShowPassword(!show_password);
    event.stopPropagation();
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (
      !credentials.email.match(
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      formIsValid = false;
      errors["email"] = " Enter a valid Email Address";
    }

    if (!credentials.email) {
      formIsValid = false;
      errors["email"] = " Cannot be empty";
    }

    if (!credentials.password) {
      formIsValid = false;
      errors["password"] = " Cannot be empty";
    }

    updateErrors(errors);
    return formIsValid;
  };

  const updateDetails = e => {
    const { name, value } = e.target;
    credentials[name] = value;
    updateCredentials({ ...credentials });
    const btnDisabled = !(name === 'password' && value && credentials.email);
    updateDisabled(btnDisabled);
  };

  const handleLogin = () => {
    updateIsLoading(true)
    let body = JSON.stringify({
      email: credentials.email,
      password: credentials.password,
    })
    if (loginType === 'Branch Login') {
      Api.branchLogin(
        { data: body },
        onLoginSuccess,
        onLoginFailure
      );
    } else {
      Api.enterpriseLogin(
        { data: body },
        onLoginSuccess,
        onLoginFailure
      );
    }
  }

  const onLoginSuccess = response => {
    updateIsLoading(false)
    const headers = response.headers
    let key = headers['x-provider-key']
    if (key !== undefined && key.length > 20) {
      storeToken(headers['x-provider-key'])
      updateLoggedIn(true)
      if (loginType === 'Branch Login') {
        storeDisplayName('Branch - ' + response['data']['display_name'])
      } else {
        storeDisplayName('Admin - ' + response['data']['display_name'])
      }
      return response
    }
  }

  const onLoginFailure = () => {
    updateIsLoading(false)
    updateNotAuthorized(true)
  }

  const renderForm = () => {
    return (
      <div className="w-full h-screen flex">
        <div className="w-3/5 bg-gray-100 flex justify-center items-center">
          <LeftPanel />
        </div>
        <div className="w-2/5 flex flex-col p-8 items-center justify-center">
          <div className="mt-8">
            <img className='h-16' src={careLynkLogo} />
            <h1 className='mb-0 text-gray-900 mt-10 text-2xl font-fig-bold leading-none'>{`${loginType} to Provider dashboard`}</h1>
            <div className='mt-12 text-gray-600 text-xs flex flex-col w-full'>
              <span className='mb-3 text-gray-700 text-sm font-fig-semibold'>Email address</span>
              <input
                id='input_username'
                type='text'
                name='email'
                placeholder='enter email'
                value={credentials.email}
                onChange={updateDetails}
                className={
                  'text-base text-gray-700 leading-4 h-12 placeholder-gray-300 font-fig-semibold pl-4 border border-gray-200 rounded-md w-96 focus:border-gray-400  bg-gray-100 focus:bg-gray-100 focus:ring-0  focus:outline-none ' +
                  (!errors["email"] ? ' focus:border-gray-400' : ' border-red-600')
                }
              />
              {errors["email"] && (
                <p className='mb-0 mt-2 text-xs font-fig-semibold text-red-600 flex flex-row'>
                  <img src={exclamatoryRedDark} className='h-3 w-3 mr-1' />
                  {errors["email"]}
                </p>
              )}
              <span className='mt-8 mb-3 text-gray-700 text-sm font-fig-semibold'>Password</span>
              <div className='flex items-end w-full'>
                <input
                  id='input_password'
                  type='password'
                  name='password'
                  placeholder='*********'
                  value={credentials.password}
                  onChange={updateDetails}
                  className={
                    `text-base  caret-blue-600 text-gray-700 placeholder-gray-300 leading-4 font-fig-semibold pl-4 border border-gray-200 focus:border-gray-400 rounded-md w-96 h-12 bg-gray-100 focus:bg-gray-100 focus:ring-0  focus:outline-none ${!credentials.password && 'pt-4 tracking-widest'}` +
                    (!errors["password"] ? ' focus:border-gray-400' : ' border-red-600')
                  }
                />
                <img
                  src={show_password ? eye : eyeoff}
                  className='inline-block h-6 w-6 relative bottom-3 right-10 cursor-pointer'
                  onClick={toggleVisiblity}
                  alt='eye_icon'
                />
              </div>
              {errors["password"] && (
                <p className='mb-0 mt-2 font-fig-semibold text-xs text-red-600 flex flex-row'>
                  <img src={exclamatoryRedDark} className='h-3 w-3 mr-1' />
                  <span className='break-words'>{errors["password"]}</span>
                </p>
              )}
              {notAuthorized && (
                <p className='mb-0 mt-2 font-fig-semibold text-xs text-red-600 flex flex-row'>
                  <img src={exclamatoryRedDark} className='h-3 w-3 mr-1' />
                  <span className='break-words'>{errorMessage}</span>
                </p>
              )}
              <button
                onClick={handleSubmit}
                disabled={disabled || isLoading}
                className={`${(disabled || isLoading) ? 'bg-gray-300 hover:bg-slate-300 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 cursor-pointer'} text-white w-96 py-4 rounded-md text-lg mt-16 font-fig-semibold focus:outline-none`}
              >
                Log in
              </button>
              <div className="flex justify-end mt-4 mr-6">
                <Link to={data.forgotPasswordLink} className="mb-6 text-blue-500">
                  Forgot password?
                </Link>
              </div>
              <div className="text-center">
                <span className="text-sm">
                  {data.loginViewText}{" "}
                  <Link to={data.loginLink} className="text-blue-500 font-medium">
                    login here
                  </Link>
                </span>
              </div>
              <p className='mb-0 font-fig-normal text-gray-600 mt-8 text-sm w-96 text-center'>
                By logging in, you will agree with ekincare’s {' '}
                <a
                  href='https://www.ekincare.com/about/terms'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-500 cursor-pointer hover:text-blue-500 hover:underline'
                >
                  Terms and conditions{' '}
                </a>
                and{' '}
                <a
                  href='https://www.ekincare.com/about/privacy'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-500 cursor-pointer hover:text-blue-500 hover:underline'
                >
                  privacy policy{' '}
                </a>
              </p>
              <div className='text-gray-500 mt-12 text-center'>A product by{' '}
                <a
                  href='https://www.ekincare.com'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-gray-700 text-xs font-fig-semibold cursor-pointer hover:text-blue-900 hover:underline'
                >
                  ekincare{' '}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>{(() => {
      if (loggedIn) {
        return (
          <div><Redirect to={"/"} /></div>
        )
      } else {
        return (
          <div>{renderForm()}</div>
        )
      }
    })()}
    </div>
  )

};

export default LoginForm;
