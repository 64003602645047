import { manage, notification, report, secure } from "../../assets/icons";

export const content = [
  {
    heading: 'Real-Time Bookings Management enables you to',
    text: ' easily view and manage all user bookings.',
    image: manage
  },
  {
    heading: 'Hassle-Free Report Uploads allow seamless uploading of',
    text: ' diagnostic reports to the ekincare system.',
    image: report
  },
  {
    heading: 'Custom Notifications keep diagnostic centers',
    text: ' updated with appointment changes or reminders.',
    image: notification
  },
  {
    heading: 'Secure Data Management protects user information',
    text: ' growing corporate demands.',
    image: secure
  }
];

export const keyPoints = [
  {
    heading: '99.9%',
    text: 'Uptime Reliability'
  },
  {
    heading: '95%',
    text: 'User Satisfaction'
  },
  {
    heading: '1 Million+',
    text: 'reports processed'
  },
];