import React from 'react';
import { loginPattern1, loginPattern2 } from '../../assets/icons';
import { content, keyPoints } from './loginContent';

const LeftPanel = () => {
  return (
    <div
      className='w-full h-full overflow-hidden bg-blue-800 pt-12 pl-24 text-white'
      style={{
        background: `url(${loginPattern1})  no-repeat top right, url(${loginPattern2}) no-repeat bottom left`,
        backgroundColor: '#03318C',
      }}
    >
      <h1 className='text-4xl font-fig-bold mb-0 pt-12 leading-none inline-block' style={{ color: '#FFC72D' }}>Provider Dashboard</h1>
      <p className='text-2xl font-fig-semibold mb-0 mt-4 mr-32 break-words leading-normal'>
      Streamlining bookings and report uploads for a
      </p>
      <p className='text-2xl font-fig-semibold mb-0 mr-32 break-words leading-normal'>
      seamless healthcare experience.
      </p>
      <div className='mt-12 w-9/12'>
        {content.map((item) => {
          return (
            <div className='flex items-center mt-8'>
              <img src={item.image} className='mr-5' />
              <p className='mb-0'>
                <div className='text-lg font-fig-normal mb-0 break-words leading-normal'>{item.heading}</div>
                <span className='text-lg font-fig-normal mb-0 break-words leading-normal'>{item.text}</span>
              </p>
            </div>
          );
        })}
      </div>
      <div className='flex justify-between mt-12 w-8/12'>
        {keyPoints.map((item) => {
          return (
            <div className='items-center mt-8 mb-0'>
              <div className='text-4xl font-fig-bold mb-0 break-words'>{item.heading}</div>
              <div className='text-sm mt-2 font-fig-normal mb-0 break-words'>{item.text}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LeftPanel;